import React from 'react';
import { Block, Layout, Title, Actions, Half, Button } from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO } from '../../../components/_index';

const GitBounty = () => {
  const { IMAGES_URI } = process.env;

  return (
    <PageLayout>
      <SEO
        title="Git Bounty | Dragonchain Strategic Projects Bounty Program"
        description="Dragonchain's strategic bounty for Git, Gitlab, and Github."
      />
      <Layout dark background="blue" header="Strategic Projects Bounty" />
      <Layout>
        <Block>
          <p>
            Project for the creation of a Dragonchain backed Github replacement system for decentralized
            software source code control.
          </p>

          <p>
            Project should integrate open source Git and Gitlab into Dragonchain with optional integration of
            Github via webhooks.
          </p>
          <Actions
            actions={[
              {
                name: 'Explore other bounties',
                color: 'blue',
                hover: 'blue-dark',
                to: '../',
              },
              {
                name: 'Go to Github',
                color: 'blue-dark',
                hover: 'blue-dark',
                blank: true,
                to: 'https://github.com/dragonchain-inc',
              },
            ]}
          />
        </Block>
      </Layout>
      <Layout dark background="blue-dark">
        <Block type="halves">
          <Half
            illustration={{
              middle: true,
              center: true,
              src: `${IMAGES_URI}/icons/flutter-rgb.svg`,
            }}
          />
          <Half>
            <Title>Git + GitLab + Github</Title>
            <p>
              Reward structure. One-time; only one team (of 1+ collaborators) will receive reward for
              successfully completing assigned project. Highlighted project; high priority.
            </p>
            <p>
              Reward Specifics. Successful completion will gain: $4000 in DRGN Bonus for: -Integrating Github
              via webhooks * All rewards paid in DRGN, based upon CoinGecko USD price at time of payment.
            </p>
            <Title tag="h3">Value in Dragons - $5,000</Title>
            <Button blank outline type="link" to="https://t.me/joinchat/IB-8KkaUJXgIJCzW3EcviA">
              Learn More
            </Button>
          </Half>
        </Block>
      </Layout>
    </PageLayout>
  );
};

export default GitBounty;
